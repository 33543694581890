import * as Sentry from '@sentry/react';
import { get, patch, post, put, remove } from 'api';
import ApiError from 'api/ApiError';
import { backendMetadataToMetaData } from 'api/mapper';
import { AddonInput } from 'api/search';
import { BackendMetaData } from 'api/types';
import UserDataHelper from 'helpers/UserDataHelper';
import { DateTime } from 'luxon';
import queryString from 'query-string';

import * as config from '../../config';
import { BulkEditResponse } from '../../containers/Import/components/ConsignmentBulkEditProvider/types';
import { toBackendPackageRequest } from '../packages/mapper';
import { PackageRequest } from '../packages/types';
import {
    backendConsignmentToConsignment,
    backendSearchResultToSearchResult,
    toBackendConsignmentPatch,
    toBackendConsignmentRequest,
} from './mapper';
import {
    BackendConsignment,
    BackendSearchResult,
    Consignment,
    ConsignmentPatch,
    ConsignmentRequest,
    ConsignmentStatus,
    SearchOptions,
    SearchResults,
} from './types';

/**
 * Turn the array of statuses to a string separated by commas.
 * In the API they also need to be lowercase. ['ACTIVE', 'IN TRANSIT'] becomes 'active,in transit,
 * Adds confirmed and return booked if ordered is selected since they are effectivly the same to the user
 * @param statuses the list of statuses
 * @returns an string of the statuses
 */
export const getStatusParam = (statuses: ConsignmentStatus[]): string => {
    if (statuses.find((status) => status === ConsignmentStatus.ORDERED)) {
        statuses.push(
            ConsignmentStatus.CONFIRMED,
            ConsignmentStatus.RETURN_BOOKED
        );
    }
    return statuses.map((s) => s.toLowerCase()).join(',');
};
/**
 * Returns a comma separated list of all statuses to be shown on the order page when no filters are active.
 * @returns an string of the statuses
 */
export const getAllStatusParam = (): string => {
    const allStatuses = [
        ConsignmentStatus.ORDERED,
        ConsignmentStatus.CANCELED,
        ConsignmentStatus.CONFIRMED,
        ConsignmentStatus.DELIVERED,
        ConsignmentStatus.IN_TRANSIT,
        ConsignmentStatus.RETURN_BOOKED,
        ConsignmentStatus.EXCEPTION,
    ];
    return getStatusParam(allStatuses);
};

export const createCartConsignment = async (
    consignment: ConsignmentRequest
): Promise<Consignment> => {
    const backendConsignment = toBackendConsignmentRequest(consignment);
    const response = await post<{ data: BackendConsignment }>(
        `${config.goDomain}v2/consignments/active`,
        backendConsignment
    );

    if (!response.parsedBody) {
        throw new ApiError(response);
    }
    return backendConsignmentToConsignment(response.parsedBody.data);
};
export const getCart = async (userId: number): Promise<Consignment[]> => {
    const params = {
        status: 'ACTIVE',
        page: 1,
        per_page: 50,
        include: 'external_source',
        filter_by_user: userId,
        order_by: 'updated_at_desc',
    };
    const response = await get<{ data: BackendConsignment[] }>(
        `${config.goDomain}v2/consignments/?${queryString.stringify(params)}`
    );

    if (!response?.parsedBody?.data) {
        const shipmentError = new Error(
            'Could not process cart call. Shipments were undefined.'
        );
        Sentry.captureException(shipmentError, {
            tags: {
                teamId: UserDataHelper.getTeamId() || 'undefined',
                userId: userId.toString(),
            },
        });
        throw new ApiError(response);
    }
    return response.parsedBody.data.map(backendConsignmentToConsignment);
};

export const getConsignments = async (
    params: Record<string, string | number>
) => {
    const response = await get<{
        data: BackendConsignment[];
        meta: BackendMetaData;
    }>(`${config.goDomain}v2/consignments/?${queryString.stringify(params)}`);

    if (response.parsedBody) {
        return {
            consignments: response.parsedBody.data.map(
                backendConsignmentToConsignment
            ),
            meta: backendMetadataToMetaData(response.parsedBody.meta),
        };
    }
    throw new ApiError(response);
};

export const getShipmentPrices = async (
    consignmentId: string,
    options: SearchOptions
): Promise<SearchResults> => {
    const url = `${config.goDomain}v2/search/${consignmentId}`;

    const response = await post<{
        data: BackendSearchResult[];
        addonInput?: AddonInput[];
    }>(url, options);
    return {
        data:
            response?.parsedBody?.data?.map(
                backendSearchResultToSearchResult
            ) || [],
        addonInput: response?.parsedBody?.addonInput || [],
        requestId: response.headers.get('x-sendify-request-id'),
    };
};

export const getQuickPriceEstimation = async (
    consignment: ConsignmentRequest,
    options?: SearchOptions
): Promise<SearchResults> => {
    const url = `${config.goDomain}v2/search/qq`;

    const response = await post<{
        data: BackendSearchResult[];
        addonInput?: AddonInput[];
    }>(url, toBackendConsignmentRequest(consignment, options));

    return {
        data:
            response?.parsedBody?.data?.map(
                backendSearchResultToSearchResult
            ) || [],
        requestId: response.headers.get('x-sendify-request-id'),
        addonInput: response?.parsedBody?.addonInput || [],
    };
};

export const saveDraft = async (consignment: ConsignmentRequest) => {
    const backendRequest = toBackendConsignmentRequest(consignment);
    const response = await post(
        `${config.goDomain}v2/consignments/draft`,
        backendRequest
    );
    return backendConsignmentToConsignment(response.parsedBody.data);
};

export const cancelBooking = async (consignmentId: string) => {
    const response = await remove(
        `${config.goDomain}v2/booking/${consignmentId}`
    );
    return response.parsedBody;
};

export const getConsignmentSearch = async (
    query: string,
    status?: ConsignmentStatus[]
) => {
    const teamId = UserDataHelper.getTeamId();
    let url = `${config.goDomain}v2/consignments/?q=${query}*&team_id=${teamId}&per_page=20&page=1&include=order`;
    if (status) {
        url = url.concat(
            `&status=${encodeURIComponent(getStatusParam(status))}`
        );
    } else {
        url = url.concat(`&status=${encodeURIComponent(getAllStatusParam())}`);
    }

    const response = await get(url);
    return response.parsedBody;
};

export const setConsignmentStatus = async (
    id: string,
    status: string
): Promise<void> => {
    const response = await put(
        `${config.goDomain}v2/consignments/${id}/state/${status}`
    );
    return response.parsedBody;
};

export const setConsignmentStatusDraft = (consignmentId: string) =>
    setConsignmentStatus(consignmentId, 'draft');

export const addExistingConsignmentToCart = (consignmentId: string) =>
    setConsignmentStatus(consignmentId, 'active');

export const setConsignmentStatusMarkedAsDevlivered = (consignmentId: string) =>
    setConsignmentStatus(consignmentId, 'delivered_marked');

export const updateConsignment = async (
    consignmentId: string,
    consignment: ConsignmentRequest
) => {
    const backendConsignment = toBackendConsignmentRequest(consignment);
    const response = await put(
        `${config.goDomain}v2/consignments/${consignmentId}`,
        backendConsignment
    );
    return backendConsignmentToConsignment(response.parsedBody.data);
};

export const patchConsignment = async (
    consignmentId: string,
    consignment: ConsignmentPatch
) => {
    const backendConsignment = toBackendConsignmentPatch(consignment);
    const response = await patch(
        `${config.goDomain}v2/consignments/${consignmentId}`,
        backendConsignment
    );
    return backendConsignmentToConsignment(response.parsedBody.data);
};

/**
 * Shares the labels of an already booked consignment with a third party, as an email.
 *
 * @param consignmentId The ID of a booked consignment. The consignment must be not be booked through Libello,
 * or this call will fail.
 * @param email The email address to share the labels with.
 */
export const shareConsignmentLabels = async (
    consignmentId: string,
    email: string
): Promise<void> => {
    const url = `${config.goDomain}v2/consignments/${consignmentId}/shareLabels`;
    await post(url, { email });
};

export const shareConsignmentTracking = async (
    consignmentId: string,
    email: string
) => {
    const response = await post(
        `${config.goDomain}v2/consignments/${consignmentId}/shareTracking`,
        { email }
    );
    return response.parsedBody;
};

export const getConsignment = async (
    consignmentId: string
): Promise<Consignment> => {
    const response = await get(
        `${config.goDomain}v2/consignments/${consignmentId}`
    );
    return backendConsignmentToConsignment(response.parsedBody.data);
};
/**
 * By calling this function you will get 10 first consignments filtered by team from team hash.
 * It's possible to filter on consignment status by sending in a list of statuses.
 */

export const getConsignmentsByTeam = async (
    teamHash: string,
    status?: ConsignmentStatus[],
    page = 1,
    perPage = 20
) => {
    let url = `${config.goDomain}v2/admin/teams/${teamHash}/consignments`;
    url = url.concat(`?per_page=${perPage}&page=${page}&include=order`);
    if (status) {
        url = url.concat(
            `&status=${encodeURIComponent(getStatusParam(status))}`
        );
    } else {
        url = url.concat(`&status=${encodeURIComponent(getAllStatusParam())}`);
    }

    const response = await get<{
        data: BackendConsignment[];
        meta: BackendMetaData;
    }>(url);
    if (response.parsedBody) {
        return {
            consignments: response.parsedBody.data.map(
                backendConsignmentToConsignment
            ),
            meta: backendMetadataToMetaData(response.parsedBody.meta),
        };
    }
    throw new ApiError(response);
};

export const deleteConsignment = async (consignmentId: string) => {
    const response = await remove(
        `${config.goDomain}v2/consignments/${consignmentId}`
    );
    return response.parsedBody;
};

export const sendPickupInstructions = async (
    consignmentId: string,
    shouldOverrideNotificationSetting?: boolean
) => {
    const response = await post(
        `${config.goDomain}v2/consignments/${consignmentId}/sendPickupInstructions`,
        {
            overrideNotificationSetting: shouldOverrideNotificationSetting,
        }
    );
    return response.parsedBody;
};

export const bulkEditPickupDate = async (
    consignmentIds: string[],
    pickupDay: DateTime
) => {
    const response = await patch<{ bulkEditErrors: BulkEditResponse }>(
        `${config.goDomain}v2/consignments/bulkEdit`,
        {
            ids: consignmentIds,
            shippingDate: pickupDay,
        }
    );
    if (response.parsedBody) {
        return response.parsedBody;
    }

    throw new ApiError(response);
};

export const bulkAddToCart = async (consignmentIds: string[]) => {
    const response = await patch<{ bulkEditErrors: BulkEditResponse }>(
        `${config.goDomain}v2/consignments/bulkEdit`,
        {
            ids: consignmentIds,
            moveToCart: true,
        }
    );

    if (response.parsedBody) {
        return response.parsedBody;
    }

    throw new ApiError(response);
};

/**
 * Adds a number of packages to several consignments.
 * All packages will be added to each consignment associated with the IDs sent in.
 * @param consignmentIds The consignments to which to add the packages. Must not be empty.
 * @param parcels Will be added to every consignment sent in.
 */
export const bulkAddParcels = async (
    consignmentIds: string[],
    parcels: PackageRequest[]
) => {
    const response = await patch<{ bulkEditErrors: BulkEditResponse }>(
        `${config.goDomain}v2/consignments/bulkEdit`,
        {
            ids: consignmentIds,
            parcels: parcels.map((p) => toBackendPackageRequest(p)),
        }
    );
    if (response.parsedBody) {
        return response.parsedBody;
    }

    throw new ApiError(response);
};
