import {
    connectIntegration,
    sendIntegrationOnboardingCancelled,
} from 'api/subscriptions';
import { ArrowRight } from 'assets/icons';
import { Button } from 'components/Buttons';
import Confirmation from 'components/IntegrationsModal/Confirmation';
import Instructions from 'components/IntegrationsModal/Instructions';
import {
    ButtonWrapper,
    SpinnerWrapper,
} from 'components/IntegrationsModal/StyledIntegrationsModal';
import Zwapgrid from 'components/IntegrationsModal/Zwapgrid';
import { Step } from 'components/IntegrationsModal/types';
import Modal, {
    ModalContent,
    ModalFooter,
    ModalHeader,
} from 'components/Modal';
import Spinner, { spinnerSize } from 'components/Spinner/Spinner';
import ApiKeyForm from 'containers/Settings/components/Api/ApiKey/ApiKeyForm';
import { ApiForm } from 'containers/Settings/components/Api/types';
import { trackIntegrationAborted } from 'external/analytics';
import { useIntegrations } from 'hooks/Integrations';
import { useTeam, useUser } from 'hooks/Queries';
import { useApiKeyInformation, useGenerateApiKey } from 'hooks/Queries/auth';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Integration } from '../../api/subscriptions/types';
import Logger from '../../helpers/LogHelper';
import { IntegrationChange } from '../APISubscriptionChange';
import Systems from './Systems';

/**
 * A modal to control the flow when connecting a user to an integration
 * Will include tier selection and payment if not already set up.
 */

const IntegrationsModal = () => {
    const {
        step,
        canGoBack,
        canGoForward,
        integration,
        credentials,
        setIntegration,
        goForward,
        goBack,
        isSubmitting,
        setIsSubmitting,
        isLoading: loadingIntegration,
        isShown,
        close,
    } = useIntegrations();

    const { t } = useTranslation('import');
    const { team, isLoading: isLoadingTeam } = useTeam();
    const { isLoading: isLoadingUser } = useUser();
    const loadingUserOrTeam = isLoadingTeam || isLoadingUser;
    const { apiKeyInformation } = useApiKeyInformation();
    const apiKeyGenerator = useGenerateApiKey();
    const [startTime, setStartTime] = useState<DateTime>();

    const getApiKey = async (data: ApiForm) => {
        try {
            await apiKeyGenerator.mutateAsync(data.userId);
        } catch (e) {
            toast.error(t('api.key.error'), {
                autoClose: false,
            });
        }
    };

    const closeManually = () => {
        trackIntegrationAborted(team?.id || '');
        const slack = 5; // In seconds, approximately the time for Zwapgrid to load
        if (
            integration &&
            startTime &&
            // The diff is negative since starTime is in the past.
            -startTime.diffNow('seconds').seconds > slack
        ) {
            // Send an email that the user has aborted the onboarding.
            // Just send it - it's not crucial for the flow that we await it.
            sendIntegrationOnboardingCancelled(integration);
        }
        setIntegration(undefined);
        setStartTime(undefined);
        close();
    };
    const onIntegrationCreated = async (
        integration: Integration,
        name: string
    ) => {
        Logger.info('onIntegrationCreated called with', { integration, name });

        setIsSubmitting(true);
        Logger.info('isSubmitting set to true');

        try {
            Logger.info('Attempting to connect integration');
            await connectIntegration({
                integration,
                name: name,
            });
            Logger.info('Integration connected successfully');
        } catch (e) {
            toast.error(t('import:onboarding.connectionError'));
        }

        setIsSubmitting(false);
        Logger.info('isSubmitting set to false');

        goForward();
        Logger.info('Proceeding to the next step with goForward');
    };

    return (
        <Modal
            showModal={isShown}
            closeOnOverlayClick={false}
            onClose={closeManually}
            width="850px"
        >
            {step !== Step.PAYMENT && step !== Step.INTEGRATION && integration && (
                <ModalHeader
                    title={t('import:onboarding.systems.title', {
                        system: integration,
                    })}
                />
            )}
            {step === Step.INTEGRATION && (
                <ModalHeader title={t('import:onboarding.title')} />
            )}
            {step === undefined ||
            loadingIntegration ||
            (step === Step.INSTRUCTIONS && loadingUserOrTeam) ? (
                <SpinnerWrapper>
                    <div data-testid="spinner" />
                    <Spinner size={spinnerSize.large} />
                </SpinnerWrapper>
            ) : (
                <>
                    {step === Step.INTEGRATION && (
                        <ModalContent>
                            <form
                                id={`integration-step-${step}`}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setIsSubmitting(true);
                                    if (integration) {
                                        goForward();
                                    }
                                    setIsSubmitting(false);
                                }}
                            >
                                <Systems />
                            </form>
                        </ModalContent>
                    )}
                    {step === Step.PAYMENT && integration && (
                        <IntegrationChange
                            integration={integration}
                            type="purchase"
                            onSuccessfulSubmit={goForward}
                        />
                    )}

                    {step === Step.INSTRUCTIONS && integration && (
                        <ModalContent>
                            <Instructions system={integration} />
                        </ModalContent>
                    )}

                    {step === Step.ZWAPGRID && team && integration && (
                        <ModalContent>
                            {apiKeyInformation?.apiKey ? (
                                <Zwapgrid
                                    integration={integration}
                                    team={team}
                                    credentials={credentials}
                                    onIntegrationStarted={() =>
                                        setStartTime(DateTime.local())
                                    }
                                    onIntegrationCreated={() => goForward()}
                                    apiKey={apiKeyInformation.apiKey}
                                />
                            ) : (
                                <ApiKeyForm
                                    inModal
                                    team={team}
                                    onSubmit={getApiKey}
                                />
                            )}
                        </ModalContent>
                    )}

                    {step === Step.CONFIRMATION && integration && (
                        <ModalContent>
                            <form
                                id={`integration-step-${step}`}
                                onSubmit={async (e) => {
                                    e.preventDefault();
                                    setIsSubmitting(true);
                                    if (integration) {
                                        await onIntegrationCreated(
                                            integration,
                                            credentials?.integrationName
                                                ? credentials?.integrationName
                                                : integration
                                        );
                                        goForward();
                                    }
                                    setIsSubmitting(false);
                                }}
                            >
                                <Confirmation integration={integration} />
                            </form>
                        </ModalContent>
                    )}
                </>
            )}
            {step !== Step.PAYMENT && (
                <ModalFooter>
                    <ButtonWrapper>
                        {canGoForward ? (
                            <Button
                                variant="primary"
                                type="submit"
                                loading={isSubmitting}
                                form={`integration-step-${step}`}
                                iconAfter={<ArrowRight fill="currentColor" />}
                            >
                                {step === Step.CONFIRMATION
                                    ? t('import:onboarding.finishButton')
                                    : t('import:onboarding.continueButton')}
                            </Button>
                        ) : (
                            <div />
                        )}
                        {canGoBack && (
                            <Button
                                variant="secondary"
                                type="button"
                                onClick={goBack}
                            >
                                {t('import:onboarding.backButton')}
                            </Button>
                        )}
                    </ButtonWrapper>
                </ModalFooter>
            )}
        </Modal>
    );
};

export default IntegrationsModal;
