/**
 * Identifies a user with FullStory. Will fail silently if FullStory has not
 * been initialized.
 * @param userId the user ID
 * @param teamHash the hash (not database id) of the team that the user is on
 * @param teamName the name of the team that the user is on
 */
export const identifyFullstoryUser = (
    userId: string,
    teamHash: string,
    teamName: string
) => {
    window.FS?.('setIdentity', {
        uid: userId,
        properties: {
            displayName: `${teamName}_${userId}`,
            teamHash,
            teamName,
        },
    });
};

/**
 * Clears the user in FullStory. Should be called at logout.Will fail silently
 * if FullStory has not been initialized.
 */
export const clearFullstoryUser = () =>
    window.FS?.('setIdentity', { anonymous: true });
