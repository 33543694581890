import * as Sentry from '@sentry/react';
import { Button } from 'components/Buttons';
import { trackHomeButtonClick } from 'external/analytics';
import { useTeam, useUser } from 'hooks/Queries';
import { Alpha2Code } from 'i18n-iso-countries';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Option } from './components/AddressForm/AddressBookSearch';

export type ConsignorType = 'sender' | 'recipient';

interface IHomeAddressPrefillRow {
    prefix: string;
    consignor: ConsignorType;
    countryOverride?: Alpha2Code;
    qqComplementValues?: {
        postalCode?: string;
        countryCode: Alpha2Code;
        state?: string;
        city: string;
        isPrivate: boolean;
    };
    setContactOption: (option: Option | null) => void;
}

const HomeAddressPrefillRow = ({
    prefix,
    countryOverride,
    setContactOption,
    qqComplementValues,
}: IHomeAddressPrefillRow) => {
    const { t } = useTranslation(['address']);

    const { trigger, setValue } = useFormContext();
    const { team } = useTeam();
    const { user } = useUser();

    /**
     * If the user has entered a postal code, then check if that is the same as the one stored
     * in the team's home address. If not, check if the cities are the same.
     * Ensure that the state and country code are also the same.
     * Ensure that we have loaded the user
     * If any of these fail, disable the fill home address button.
     */
    const disableFillHomeAddress =
        (qqComplementValues &&
            !(
                ((qqComplementValues.postalCode &&
                    qqComplementValues.postalCode ===
                        team?.homeAddress?.postalCode) ||
                    qqComplementValues.city === team?.homeAddress?.city) &&
                qqComplementValues.countryCode ===
                    team?.homeAddress?.countryCode &&
                qqComplementValues.state === team?.homeAddress?.state
            )) ||
        !user ||
        !team;

    const fillHomeAddress = async () => {
        try {
            const homeAddress = {
                [`${prefix}.isPrivate`]: false,
                [`${prefix}.name`]: team?.name || '',
                [`${prefix}.addressLine1`]:
                    team?.homeAddress?.addressLine1 || '',
                [`${prefix}.addressLine2`]:
                    team?.homeAddress?.addressLine2 || '',
                [`${prefix}.addressLine3`]:
                    team?.homeAddress?.addressLine3 || '',
                [`${prefix}.city`]: team?.homeAddress?.city || '',
                [`${prefix}.postalCode`]: team?.homeAddress?.postalCode || '',
                [`${prefix}.countryCode`]:
                    countryOverride ||
                    team?.homeAddress?.countryCode ||
                    team?.systemCountry,
                [`${prefix}.contactName`]: `${user?.firstName || ''} ${
                    user?.lastName || ''
                }`,
                [`${prefix}.contactPhone`]: user?.mobileNumber || '',
                [`${prefix}.contactEmail`]: user?.email || '',
            };

            Object.keys(homeAddress).forEach((key) =>
                setValue(key, homeAddress[key])
            );
            trigger(Object.keys(homeAddress));
            setContactOption(null);
            trackHomeButtonClick(prefix);
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    return (
        <RowWrapper>
            <Button
                disabled={disableFillHomeAddress}
                type="button"
                variant="primary"
                size="small"
                onClick={fillHomeAddress}
            >
                {t('button.useHomeAddress')}
            </Button>
        </RowWrapper>
    );
};

export default HomeAddressPrefillRow;

const RowWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
`;
